import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ManagePanelService } from '../services/manage-panel.service';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-manage-panel',
  templateUrl: './manage-panel.component.html',
  styleUrls: ['./manage-panel.component.css']
})
export class ManagePanelComponent implements OnInit {

  constructor(
    private manageService: ManagePanelService,
    private toastr: ToastrService,
  ) { }

  manageData: any;

  ngOnInit(): void {
    this.manageService.getData('painel-dados').snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() as object })
        )
      )
    ).subscribe(data => {
      this.manageData = data;
    });
  }

  submit(panelForm: NgForm, position) {
    const dataPanel = panelForm.form.value;
    let objDoc = this.getIdDoc(position);

    if (objDoc.codigo === 'perguntasRespostas' || objDoc.codigo === 'regrasAgendamento' || objDoc.codigo === 'sintomas') {
      let questionAnswerData = { accordion: [] };
      for (let i = 0; i < dataPanel.index; i++) {
        questionAnswerData.accordion.push(
          { titulo: dataPanel['titulo' + i], texto: dataPanel['texto' + i], referencia: objDoc.codigo + i }
        )
      }
      this.updateDoc(objDoc.id, questionAnswerData);
    } else {
      this.updateDoc(objDoc.id, dataPanel);
    }
  }

  updateDoc(id, data) {
    this.manageService.update(id, data).then(() => {
      this.toastr.success('Seus dados foram atualizados', 'Tudo certo!');
    })
      .catch(err => {
        this.toastr.error('Ocorreu um erro ao salvar seus dados. Tente novamente mais tarde.', 'Ah não!');
        console.log(err);
      });;
  }

  addDoc(position) {
    let firestoreData = this.getIdDoc(position);
    firestoreData.accordion.push(
      { titulo: 'Título', texto: 'Insira seu texto', referencia: 'codigo referencia ao titulo (descrever em uma palavra)' }
    )
    this.manageService.add(firestoreData.id, firestoreData).then(() => {
      this.toastr.success('Adicionado novo registro.', 'Tudo certo!');
    }).catch(err => {
      this.toastr.error('Ocorreu um erro ao salvar seus dados. Tente novamente mais tarde.', 'Ah não!');
      console.log(err);
    });;
  }

  deleteDoc(position, dataToRemove) {
    let firestoreData = this.getIdDoc(position);
    firestoreData.accordion = firestoreData.accordion.filter(f => f.referencia !== dataToRemove)
    this.manageService.remove(firestoreData.id, firestoreData).then(() => {
      this.toastr.success('Seu registro foi deletado.', 'Tudo certo!');
    }).catch(err => {
      this.toastr.error('Ocorreu um erro ao tentar deletar seu registro. Tente novamente mais tarde.', 'Ah não!');
      console.log(err);
    });;
  }

  getIdDoc(position) {
    return this.manageData[position];
  }
}