import { Component, HostListener, OnInit } from '@angular/core';
import { ScrollToElementService } from 'src/app/services/scroll-to-element.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {

  constructor(private scrollService: ScrollToElementService) { }
  activateScroll: boolean = false;
  activeSection: string = "home";

  @HostListener('window:scroll', ['$event'])
  scrollHandler() {
    this.activeSectionByScroll();
  }

  ngOnInit(): void {
  }

  scroll(el: string) {
    this.activeSection = el;
    this.scrollService.scroll(el);

    // Validation to remove show of collapse in mobile/medium screens
    if (document.getElementById('navbarSupportedContent').className.includes('show')) {
      document.getElementById('navbarSupportedContent').classList.remove('show');
    }
  }

  activeSectionByScroll() {
    let servicesElement = document.getElementById('services');
    let schedulingElement = document.getElementById('scheduling');
    let serviceRules = document.getElementById('serviceRules');
    let questionAnswerElement = document.getElementById('questionAndAnswer');
    let symptoms = document.getElementById('symptoms');

    if (window.scrollY <= (servicesElement.offsetTop - 300)) {
      this.activeSection = "home";
    } else if (window.scrollY >= (servicesElement.offsetTop - 300) && window.scrollY <= (servicesElement.offsetTop + 300)) {
      this.activeSection = "services";
    } else if (window.scrollY >= servicesElement.offsetTop && window.scrollY <= (schedulingElement.offsetTop + 300)) {
      this.activeSection = "scheduling";
    } else if (window.scrollY >= schedulingElement.offsetTop && window.scrollY <= (questionAnswerElement.offsetTop + 150)) {
      this.activeSection = "questionAndAnswer";
    } else if (window.scrollY >= questionAnswerElement.offsetTop && window.scrollY <= (symptoms.offsetTop)) {
      this.activeSection = "symptoms";
    } else if (window.scrollY >= symptoms.offsetTop && window.scrollY <= (serviceRules.offsetTop)) {
      this.activeSection = "serviceRules";
    } else {
      this.activeSection = "whoWeAre";
    }
  }

}
