import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ManagePanelService } from '../services/manage-panel.service';

@Component({
  selector: 'app-symptoms',
  templateUrl: './symptoms.component.html',
  styleUrls: ['./symptoms.component.css']
})
export class SymptomsComponent implements OnInit {

  constructor(
    private manageService: ManagePanelService
  ) { }

  dataToShow: Observable<any>;

  ngOnInit(): void { }

}
