import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ManagePanelService } from 'src/app/services/manage-panel.service';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css']
})
export class AccordionComponent implements OnInit {
  @Input() tableName: string;
  @Input() color: 'light' | 'dark' = 'light';

  constructor(
    private manageService: ManagePanelService
  ) { }

  dataToShow: Observable<any>;

  ngOnInit(): void {
    this.getData(this.tableName);
  }

  getData(tableName: string) {
    this.manageService.getSpecificData(tableName).subscribe(data => {
      this.dataToShow = of(data[0]);
    });
  }

}
