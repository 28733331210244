import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  credentials = {
    email: '',
    password: '',
  };
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  doLogin() {
    this.authService.authUser(this.credentials);
    // if (this.credentials.email === 'teste' && this.credentials.password === '1234') {
      
    // } else {
    //   alert("e-mail ou senha inválido")
    // }
  }

}
