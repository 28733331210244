// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyD5NVzU7faWcDtvQQR3dCvD2yigI2f0bp0",
    authDomain: "plena-psi.firebaseapp.com",
    projectId: "plena-psi",
    storageBucket: "plena-psi.appspot.com",
    messagingSenderId: "891582642989",
    appId: "1:891582642989:web:8dc6a9f131170fc8c3b1a4",
    measurementId: "G-2FLTEPGTNH"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
