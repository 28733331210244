import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopBarComponent } from './shared/top-bar/top-bar.component';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { SchedulingComponent } from './scheduling/scheduling.component';
import { QuestionsAndAnswersComponent } from './questions-and-answers/questions-and-answers.component';
import { SymptomsComponent } from './symptoms/symptoms.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { ServicesProvidedComponent } from './services-provided/services-provided.component';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './guards/auth-guard.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { ManagePanelComponent } from './manage-panel/manage-panel.component';
import { ManagePanelService } from './services/manage-panel.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { defineLocale, ptBrLocale } from 'ngx-bootstrap/chronos';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';
import { AutosizeModule } from 'ngx-autosize';
import { RulesServiceComponent } from './rules-service/rules-service.component';

defineLocale('pt-br', ptBrLocale);

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    LoginComponent,
    HomeComponent,
    SchedulingComponent,
    QuestionsAndAnswersComponent,
    SymptomsComponent,
    WhoWeAreComponent,
    ServicesProvidedComponent,
    ManagePanelComponent,
    RulesServiceComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    AutosizeModule
  ],
  providers: [
    AuthService,
    AuthGuardService,
    AngularFirestore,
    ManagePanelService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private bsLocaleService: BsLocaleService) {
    this.bsLocaleService.use('pt-br');
  }
}
