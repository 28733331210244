<div class="row h-100-vh bg-main">
    <div class="col-md-12 text-center mt-2-4-em">
        <h3 class="main-title c-main-title">{{ (dataToShow | async)?.quebraUm }}</h3>
        <h5 class="subtitle">{{ (dataToShow | async)?.quebraDois }}</h5>
    </div>
    <div id="carouselCardsScheduling" class="carousel slide show-md-mobile" data-bs-interval="false">
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselCardsScheduling" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Conflitos na relação"></button>
            <button type="button" data-bs-target="#carouselCardsScheduling" data-bs-slide-to="1"
                aria-label="Questões pessoais"></button>
            <button type="button" data-bs-target="#carouselCardsScheduling" data-bs-slide-to="2"
                aria-label="Outros"></button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active" #conflitosRelacaoMobile>
                <app-card-scheduling title='Conflitos na Relação' typeIcon='conflitos-relacao'
                    [activeCard]="activateCouple"></app-card-scheduling>
            </div>
            <div class="carousel-item" #questoesPessoaisMobile>
                <app-card-scheduling title='Questões Pessoais' typeIcon='questoes-pessoais'
                    [activeCard]="activatePersonal"></app-card-scheduling>
            </div>
            <div class="carousel-item" #outrosMobile>
                <app-card-scheduling title='Outros' typeIcon='outros' [activeCard]="activateOthers">
                </app-card-scheduling>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselCardsScheduling"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselCardsScheduling"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
    <div class="col-md-12 hidden-md" style="display: flex; justify-content: space-evenly;">
        <div class="height-section-cards" #conflitosRelacao>
            <app-card-scheduling title='Conflitos na Relação' typeIcon='conflitos-relacao'
                [activeCard]="activateCouple"></app-card-scheduling>
        </div>
        <div class="height-section-cards" #questoesPessoais>
            <app-card-scheduling title='Questões Pessoais' typeIcon='questoes-pessoais' [activeCard]="activatePersonal">
            </app-card-scheduling>
        </div>
        <div class="height-section-cards" #outros>
            <app-card-scheduling title='Outros' typeIcon='outros' [activeCard]="activateOthers"></app-card-scheduling>
        </div>
    </div>
    <div class="col-md-12 text-center" style="margin-top: .3em;">
        <h5 class="title-baseboard">{{ (dataToShow | async)?.quebraTres }}</h5>
        <button class="btn btn-fill mt-5" data-bs-toggle="modal" data-bs-target="#modalPreenchimento"
            [disabled]="activateBtn()" [ngClass]="{'btn-shadow': !activateBtn()}"
            aria-label="prosseguir com preenchimento">Prosseguir com
            preenchimento</button>
    </div>
</div>

<!-- Modal agendamento -->
<div class="modal fade" id="modalPreenchimento" tabindex="-1" aria-labelledby="modalPreenchimentoLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content" style="padding-bottom: 1em;">
            <div class="modal-header">
                <h5 class="modal-title f-md-16" id="modalPreenchimentoLabel">Preencha o formulário</h5>
                <button #closeModal type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="fechar"></button>
            </div>
            <div class="modal-body mt-1">
                <div class="container-fluid pd-0">
                    <form [formGroup]="formSchedule" (ngSubmit)="submit()" novalidate>
                        <div class="row">
                            <label class="title-radio-section mb-md"
                                [ngClass]="{'color-error': formSchedule.controls['reasonRadio'].invalid && formSchedule.controls['reasonRadio'].touched}">Motivo</label>
                            <div class="section-radio pd-0">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="conflictRadio"
                                        value="conflictValue" formControlName="reasonRadio">
                                    <label class="form-check-label" for="conflictRadio"
                                        [ngClass]="{'color-error': formSchedule.controls['reasonRadio'].invalid && formSchedule.controls['reasonRadio'].touched}">Conflitos
                                        na Relação</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="personalRadio"
                                        value="personalValue" formControlName="reasonRadio">
                                    <label class="form-check-label" for="personalRadio"
                                        [ngClass]="{'color-error': formSchedule.controls['reasonRadio'].invalid && formSchedule.controls['reasonRadio'].touched}">Questões
                                        Pessoais</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="othersRadio" value="othersValue"
                                        formControlName="reasonRadio">
                                    <label class="form-check-label" for="othersRadio"
                                        [ngClass]="{'color-error': formSchedule.controls['reasonRadio'].invalid && formSchedule.controls['reasonRadio'].touched}">Outros</label>
                                </div>
                            </div>
                            <div *ngIf="formSchedule.controls['reasonRadio'].invalid 
                                        && (formSchedule.controls['reasonRadio'].dirty 
                                        || formSchedule.controls['reasonRadio'].touched)">
                                <div *ngIf="formSchedule.controls['reasonRadio'].errors.required" class="error-label">
                                    É preciso selecionar um motivo
                                </div>
                            </div>
                            <div class="col-md-12">
                                <input type="text" class="form-control mb-10" placeholder="Como prefere ser chamado?"
                                    aria-label="Como prefere ser chamado?" name="name" maxlength="150"
                                    formControlName="name"
                                    [ngClass]="{'error-active': formSchedule.controls['name'].invalid && formSchedule.controls['name'].touched}">
                                <div *ngIf="formSchedule.controls['name'].invalid 
                                        && (formSchedule.controls['name'].dirty 
                                        || formSchedule.controls['name'].touched)">
                                    <div *ngIf="formSchedule.controls['name'].errors.required" class="error-label">
                                        É preciso informar um nome
                                    </div>
                                </div>
                                <small>Preencha o número com DDD, ex: (21) 98765-4321</small>
                                <input type="tel" class="form-control mb-10" placeholder="Telefone/Whatsapp"
                                    aria-label="Telefone/Whatsapp" formControlName="phone" maxlength="17"
                                    [ngClass]="{'error-active': formSchedule.controls['phone'].invalid && formSchedule.controls['phone'].touched}">
                                <div *ngIf="formSchedule.controls['phone'].invalid 
                                        && (formSchedule.controls['phone'].dirty 
                                        || formSchedule.controls['phone'].touched)">
                                    <div *ngIf="formSchedule.controls['phone'].errors.required" class="error-label">
                                        É preciso informar um número de telefone
                                    </div>
                                </div>
                                <div class="section-input-date-age j-center-a-center">
                                    <input type="text" class="form-control" placeholder="Email" aria-label="Email"
                                        formControlName="email" maxlength="100" style="max-width: 70%;">
                                   
                                    <input type="text" class="form-control" style="max-width: 30%;" placeholder="Idade"
                                        aria-label="Idade" formControlName="age" mask="0*" maxlength="3">
                                </div>
                                <div *ngIf="formSchedule.controls['email'].invalid 
                                    && (formSchedule.controls['email'].dirty 
                                    || formSchedule.controls['email'].touched)">
                                <div *ngIf="formSchedule.controls['email'].errors.required" class="error-label">
                                    É preciso informar o email
                                </div>
                              </div>
                            </div>
                            <div class="text-center mb-2">
                                <p class="title-section-btn">Para quando deseja atendimento?</p>
                                <button type="button" class="btn btn-primary btn-book-appointment"
                                    style="margin-right: 10px;"
                                    [ngClass]="{'btn-book-appointment-selected': dayScheduled === 'today'}"
                                    (click)="changeSchedule('today')">Ainda
                                    hoje</button>
                                <button type="button" class="btn btn-primary btn-book-appointment"
                                    [ngClass]="{'btn-book-appointment-selected': dayScheduled === 'anotherDay'}"
                                    (click)="changeSchedule('anotherDay')">Próximos dias</button>
                            </div>
                            <div *ngIf="formSchedule.controls['daySelected'].invalid 
                                        && (formSchedule.controls['daySelected'].dirty 
                                        || formSchedule.controls['daySelected'].touched)">
                                <div *ngIf="formSchedule.controls['daySelected'].errors.required" class="error-label">
                                    É preciso escolher uma data
                                </div>
                            </div>
                            <div *ngIf="dayScheduled === 'today'" class="text-center pick-time mt-4">
                                <label for="timePicker" class="label-pick-time"
                                    [ngClass]="{'color-error': formSchedule.controls['timePicker'].invalid && formSchedule.controls['timePicker'].touched}">Escolha
                                    um horário</label>
                                <timepicker id="timePicker" formControlName="timePicker" [showMeridian]="false">
                                </timepicker>
                            </div>
                            <div class="col-md-12 mt-3">
                                <hr>
                                <button type="submit" class="btn btn-primary button-send" [disabled]="formSchedule.invalid">Enviar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>