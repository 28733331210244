<div class="row bg-main" style="height: auto; padding-bottom: 2em;">
    <div class="col-md-12">
        <div class="section-img-text max-wd-mg-auto">
            <div class="flex-order-lg">
                <div class="center-mobile">
                    <img src="../../assets/images/casal.jpg" class="img-service">
                </div>
                <div class="section-text-card">
                    <h3 class="main-title">{{ (dataToShow | async)?.quebraUmTitulo }}</h3>
                    <h5 class="subtitle" innerHTML='{{ (dataToShow | async)?.quebraUm }}'></h5>
                </div>
            </div>
            <hr>
            <div class="flex-order-lg">
                <div class="order-img-right center-mobile">
                    <img src="../../assets/images/individual.jpg" class="img-service">
                </div>
                <div class="section-text-card pd-left-0-pd-right-2">
                    <h3 class="main-title">{{ (dataToShow | async)?.quebraDoisTitulo }}</h3>
                    <h5 class="subtitle" innerHTML='{{ (dataToShow | async)?.quebraDois }}'></h5>
                </div>
            </div>
        </div>
    </div>
</div>