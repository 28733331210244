<nav class="navbar navbar-expand-lg navbar-light top-bar fixed-top">
    <a class="navbar-brand" href="#"></a>
    <button class="btn-scheduling-mobile btn-show-mobile" (click)="scroll('scheduling')">Agendar Consulta</button>

    <button class="navbar-toggler mg-r-1" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
            <li class="nav-item" [ngClass]="{'actived-link': activeSection == 'home'}">
                <a class="nav-link" [ngClass]="{'color-menu-title': activeSection != 'home'}" aria-current="page"
                    (click)="scroll('home')">Home</a>
            </li>
            <li class="nav-item" [ngClass]="{'actived-link': activeSection == 'services'}">
                <a class="nav-link" [ngClass]="{'color-menu-title': activeSection != 'services'}"
                    (click)="scroll('services')">Serviços</a>
            </li>
            <li class="nav-item" [ngClass]="{'actived-link': activeSection == 'scheduling'}">
                <a class="nav-link" [ngClass]="{'color-menu-title': activeSection != 'scheduling'}"
                    (click)="scroll('scheduling')">Agendar Consulta</a>
            </li>
            <li class="nav-item" [ngClass]="{'actived-link': activeSection == 'questionAndAnswer'}">
                <a class="nav-link" [ngClass]="{'color-menu-title': activeSection != 'questionAndAnswer'}"
                    (click)="scroll('questionAndAnswer')">Perguntas e Respostas</a>
            </li>
            <li class="nav-item" [ngClass]="{'actived-link': activeSection == 'symptoms'}">
                <a class="nav-link" [ngClass]="{'color-menu-title': activeSection != 'symptoms'}"
                    (click)="scroll('symptoms')">Sintomas</a>
            </li>
            <li class="nav-item" [ngClass]="{'actived-link': activeSection == 'serviceRules'}">
                <a class="nav-link" [ngClass]="{'color-menu-title': activeSection != 'serviceRules'}"
                    (click)="scroll('serviceRules')">Regras</a>
            </li>
            <li class="nav-item" [ngClass]="{'actived-link': activeSection == 'whoWeAre'}">
                <a class="nav-link" [ngClass]="{'color-menu-title': activeSection != 'whoWeAre'}"
                    (click)="scroll('whoWeAre')">Quem Somos</a>
            </li>
        </ul>
    </div>
</nav>