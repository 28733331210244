import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ManagePanelService } from '../services/manage-panel.service';

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.css']
})
export class WhoWeAreComponent implements OnInit {

  constructor(
    private manageService: ManagePanelService
  ) {
    this.getData();
  }

  dataToShow: Observable<any>;

  ngOnInit(): void {
  }

  getData() {
    this.manageService.getSpecificData('quemSomos').subscribe(data => {
      this.dataToShow = of(data[0]);
    });
  }

}
