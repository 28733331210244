import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ManagePanelService {

  constructor(
    public auth: AuthService,
    private afs: AngularFirestore,
  ) {
  }

  manageCollectionRef: AngularFirestoreCollection<any>;
  manage$: Observable<any[]>;

  getData(nameTable: string) {
    return this.afs.collection(nameTable);
  }

  update(id: string, data: any): Promise<void> {
    return this.afs.collection('painel-dados').doc(id).update(data);
  }

  add(id: string, data: any) {
    return this.afs.collection('painel-dados').doc(id).set(data);
  }

  remove(id: string, data: any) {
    return this.afs.collection('painel-dados').doc(id).set(data);
  }

  getSpecificData(dataToFilter: string) {
    return this.afs.collection('painel-dados').snapshotChanges().pipe(
      map(changes => {
        let obj = changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() as object })
        )
        return obj.filter((f: any) => f.codigo === dataToFilter);
      })
    )
  }
}
