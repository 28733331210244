import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class SchedulingFormService {

  constructor(
    private afs: AngularFirestore,
  ) { }

  insert(data: any): any {
    this.insertMail(data);
    return this.afs.collection('agendamento').add(data);
  }

  insertMail(data: any): any {
    let reason = '';

    switch (data.reasonRadio) {
      case 'conflictValue':
        reason = 'Conflitos na relação';
        break;
      case 'personalValue':
        reason = 'Questões pessoais';
        break;
      case 'othersValue':
        reason = 'Outros';
        break;
      default:
        reason = 'Nenhum motivo escolhido';
    }

    const lis = [
      `<li>Razão: ${ reason }</li>`,
      `<li>Email: ${ data.email }</li>`,
      `<li>Nome: ${ data.name }</li>`,
      `<li>Telefone: ${ data.phone }</li>`,
      `<li>Idade: ${ data.age } anos</li>`,
      `<li>Dia: ${ data.daySelected }</li>`
    ];

    if (data.timePicker !== 'NaN:NaN') {
      lis.push(`<li>Horário: ${ data.timePicker }</li>`);
    }

    return this.afs.collection('mail').add({
      to: [ 'plenapsi@gmail.com' ],
      replyTo: data.email,
      message: {
        subject: 'Novo agendamento pelo site',
        html: `
          <ul>
            ${ lis.join('') }
          </ul>
          <a href="tel:${data.phone}">Clique aqui para ligar para ${data.name}</a>
        `
      }
    });
  }
}
