<div class="container-fluid bg-full h-100">
    <div class="row" style="height: 100%;">
        <div class="col-md-12">
            <div class="center-card-login">
                <form id="loginForm" #loginForm="ngForm" (ngSubmit)="doLogin()" class="form-login row">
                    <img src="../../assets/logo.svg" width="250" height="250">
                    <!-- <h3>Logo</h3> -->
                    <input type="text" class="form-control" placeholder="Email" aria-label="Email" name="email"
                        [(ngModel)]="credentials.email" autocomplete="email">
                    <input type="password" class="form-control" placeholder="Senha" aria-label="Senha" name="password"
                        [(ngModel)]="credentials.password" autocomplete="on">
                    <button type="submit" class="btn btn-primary btn-login">Fazer Login</button>
                </form>
            </div>
        </div>
    </div>
</div>