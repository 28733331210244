<div class="accordion m-80-auto {{ color }}" id="accordionExample">
    <ng-container *ngFor="let item of (dataToShow | async)?.accordion">
        <div class="accordion-item">
            <h2 class="accordion-header">
                <button class="accordion-button text-color collapsed" type="button" data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#' + item.referencia" aria-expanded="false" aria-controls="collapseOne">
                    {{item.titulo}}
                </button>
            </h2>
            <div [id]="item.referencia" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <p innerHTML="{{item.texto}}"></p>
                </div>
            </div>
        </div>
    </ng-container>
</div>