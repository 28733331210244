import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollToElementService {

  constructor() { }

  scroll(el: string) {
    const yOffset = -50;
    const element = document.getElementById(el);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}
