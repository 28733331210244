<div class="row bg-main">
    <div class="col-md-12 text-center mt-3">
        <h3 class="main-title">Painel Administrativo</h3>
        <hr>
        <div class="max-width-80-center">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link title-nav-tabs" id="home-tab" data-bs-toggle="pill"
                        data-bs-target="#homepage" type="button" role="tab" aria-controls="homepage"
                        aria-selected="true">Homepage</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link title-nav-tabs" id="profile-tab" data-bs-toggle="tab"
                        data-bs-target="#servicos" type="button" role="tab" aria-controls="servicos"
                        aria-selected="false">Serviços</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link title-nav-tabs" id="contact-tab" data-bs-toggle="tab"
                        data-bs-target="#agendamento" type="button" role="tab" aria-controls="agendamento"
                        aria-selected="false">Agendamento</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link title-nav-tabs" id="contact-tab" data-bs-toggle="tab"
                        data-bs-target="#regrasAgendamento" type="button" role="tab" aria-controls="regrasAgendamento"
                        aria-selected="false">Regras de Agendamento</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link title-nav-tabs" id="contact-tab" data-bs-toggle="tab"
                        data-bs-target="#perguntasRespostas" type="button" role="tab" aria-controls="perguntasRespostas"
                        aria-selected="false">Perguntas e Respostas</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link title-nav-tabs" id="contact-tab" data-bs-toggle="tab"
                        data-bs-target="#quemSomos" type="button" role="tab" aria-controls="quemSomos"
                        aria-selected="false">Quem somos</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link title-nav-tabs" id="contact-tab" data-bs-toggle="tab"
                        data-bs-target="#sintomas" type="button" role="tab" aria-controls="sintomas"
                        aria-selected="false">Sintomas</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div *ngFor="let item of manageData; let i = index" class="tab-pane fade content-inputs"
                    id="{{item.codigo}}" role="tabpanel" aria-labelledby="home-tab">
                    <div class="content-text-editable">
                        <form #formInputs="ngForm" (submit)="submit(formInputs, i)">
                            <ng-container
                                *ngIf="item.codigo === 'perguntasRespostas' || item.codigo === 'regrasAgendamento' || item.codigo === 'sintomas'">
                                <ng-container *ngFor="let accordion of item.accordion; let index = index">
                                    <input style="display: none;" name="index" ngModel="{{index + 1}}">
                                    <label>Título {{ index + 1 }}</label>
                                    <input class="form-control mb-2" name="titulo{{index}}"
                                        ngModel="{{ accordion.titulo }}">
                                    <label>Texto {{ index + 1 }}</label>
                                    <textarea class="form-control mb-4" id="accordion{{index}}" name="texto{{index}}"
                                        ngModel="{{ accordion.texto }}"></textarea>
                                    <button class="btn btn-danger" (click)="deleteDoc(i, accordion.referencia)"
                                        aria-label="deletar registro">Deletar</button>
                                    <hr>
                                </ng-container>
                                <a (click)="addDoc(i)" aria-label="adicionar novo registro"
                                    title="adicionar novo registro">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                    </svg>
                                </a>
                            </ng-container>
                            <ng-container
                                *ngIf="item.codigo !== 'perguntasRespostas' && item.codigo !== 'servicos' && item.codigo !== 'regrasAgendamento' && item.codigo !== 'sintomas'">
                                <ng-container *ngIf="item.quebraUm !== '' && item.quebraUm != undefined">
                                    <label for="quebraUm">{{ item.quebraUmTitulo }}</label>
                                    <textarea class="form-control mb-4" id="quebraUm" name="quebraUm" autosize
                                        [minRows]="4" ngModel="{{ item.quebraUm }}"></textarea>
                                </ng-container>

                                <ng-container *ngIf="item.quebraDois !== '' && item.quebraDois != undefined">
                                    <label for="quebraDois">{{ item.quebraDoisTitulo }}</label>
                                    <textarea class="form-control mb-4" id="quebraDois" name="quebraDois" autosize
                                        [minRows]="4" ngModel="{{ item.quebraDois }}"></textarea>
                                </ng-container>

                                <ng-container *ngIf="item.quebraTres !== '' && item.quebraTres != undefined">
                                    <label for="quebraTres">{{ item.quebraTresTitulo }}</label>
                                    <textarea class="form-control mb-4" id="quebraTres" name="quebraTres" autosize
                                        [minRows]="4" ngModel="{{ item.quebraTres }}"></textarea>
                                </ng-container>

                                <ng-container *ngIf="item.quebraQuatro !== '' && item.quebraQuatro != undefined">
                                    <label for="quebraQuatro">{{ item.quebraQuatroTitulo }}</label>
                                    <textarea class="form-control mb-4" id="quebraQuatro" name="quebraQuatro" autosize
                                        [minRows]="4" ngModel="{{ item.quebraQuatro }}"></textarea>
                                </ng-container>

                                <ng-container *ngIf="item.quebraCinco !== '' && item.quebraCinco != undefined">
                                    <label for="quebraCinco">{{ item.quebraCincoTitulo }}</label>
                                    <textarea class="form-control mb-4" id="quebraCinco" name="quebraCinco" autosize
                                        [minRows]="4" ngModel="{{ item.quebraCinco }}"></textarea>
                                </ng-container>

                                <ng-container
                                    *ngIf="item.quebraTextareaUm !== '' && item.quebraTextareaUm != undefined">
                                    <label for="textAreaUm">{{ item.quebraTextareaUmTitulo }}</label>
                                    <textarea class="form-control h-150" id="textAreaUm" name="quebraTextareaUm"
                                        autosize [minRows]="4" ngModel="{{ item.quebraTextareaUm }}"></textarea>
                                </ng-container>

                                <ng-container
                                    *ngIf="item.quebraTextareaDois !== '' && item.quebraTextareaDois != undefined">
                                    <label for="textAreaDois">{{ item.quebraTextareaUmTitulo }}</label>
                                    <textarea class="form-control h-150" id="textAreaDois" name="quebraTextareaDois"
                                        autosize [minRows]="4" ngModel="{{ item.quebraTextareaDois }}"></textarea>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="item.codigo === 'servicos'">
                                <input class="form-control mb-4" id="quebraUmTitulo" name="quebraUmTitulo"
                                    ngModel="{{ item.quebraUmTitulo }}">
                                <textarea class="form-control mb-4" id="quebraUm" name="quebraUm"
                                    ngModel="{{ item.quebraUm }}"></textarea>
                                <input class="form-control mb-4" id="quebraDoisTitulo" name="quebraDoisTitulo"
                                    ngModel="{{ item.quebraDoisTitulo }}">
                                <textarea class="form-control mb-4" id="quebraDois" name="quebraDois"
                                    ngModel="{{ item.quebraDois }}"></textarea>
                            </ng-container>
                            <button class="btn btn-primary btn-save" type="submit">Salvar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>