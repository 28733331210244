import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ManagePanelService } from '../services/manage-panel.service';
import { ScrollToElementService } from '../services/scroll-to-element.service';
import Typewriter from 't-writer.js'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {

  constructor(
    private manageService: ManagePanelService,
    private scrollService: ScrollToElementService,
  ) {
    this.getData();
  }

  dataToShow: Observable<any>;
  writerStarted: boolean = false;

  ngOnInit(): void {
  }

  getData() {
    this.manageService.getSpecificData('homepage').subscribe(data => {
      this.dataToShow = of(data[0]);
      this.updateDynamicText(data[0]);
    });
  }

  updateDynamicText(data) {
    const splitDataText = data.quebraTres.split(';');
    this.activateDynamicText(splitDataText);
  }

  activateDynamicText(listText) {
    if (!this.writerStarted) {
      const target = document.querySelector('.tw');
      const writer = new Typewriter(target, {
        loop: true,
        typeSpeed: 80,
        deleteSpeed: 80,
        typeColor: '#605383',
        cursorColor: '#605383'
      })

      for (let i = 0; i < listText.length; i++) {
        writer
          .type(listText[i])
          .rest(2000)
          .changeOps({ deleteSpeed: 80 })
          .remove(listText[i].length);
      }
      writer.start();
      this.writerStarted = true;
    }
  }

  scrollToServices() {
    this.scrollService.scroll('services');
  }

  openWhatsappChat() {
    const text = "Olá, gostaria de saber mais sobre as consultas"
    const url = 'https://wa.me/5541998874843?text=' + text;
    window.open(url, '_blank');
  }
}
