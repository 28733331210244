<div class="fixed-icon-whatsapp" (click)="openWhatsappChat()">
    <img src="../../assets/icons/whatsapp-btn.svg" class="icon-whatsapp">
</div>
<div class="row h-100-vh bg-main" id="home">
    <div class="col-md-12 mt-4">
        <div class="main-section">
            <p class="subtitle" innerHTML='{{ (dataToShow | async)?.quebraUm }}'></p>
            <p class="main-title">{{ (dataToShow | async)?.quebraDois }} <b class="tw"></b></p>
        </div>
    </div>
    <div class="card-section-bg hidden-cards-section">
        <div class="cards-info text-center">
            <img src="../../assets/icons/profissionais-qualificados.svg" class="card-icon-home">
            <h3 class="card-title-home">Profissionais qualificados</h3>
            <p class="card-subtitle-home">Conectamos você a profissionais <b>psicólogos especializados</b></p>
        </div>
        <div class="cards-info text-center">
            <img src="../../assets/icons/atendimento-seguro.svg" class="card-icon-home">
            <h3 class="card-title-home">Atendimento seguro</h3>
            <p class="card-subtitle-home">Atendimento <b>familiar/casal</b> e <b>individual</b> Online, seguro e a
                <b>qualquer hora</b>
            </p>
        </div>
        <div class="cards-info text-center">
            <img src="../../assets/icons/pagamento-apos-consulta.svg" class="card-icon-home">
            <h3 class="card-title-home">Pagamento só depois da consulta</h3>
            <p class="card-subtitle-home">Você pode <b>pagar</b> em data <b>posterior</b> ao seu <b>atendimento</b>
            </p>
        </div>
    </div>
    <div class="card-section-bg show-carousel">
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner carousel-inner-cards-section">
                <div class="carousel-item active" data-bs-interval="5000">
                    <div class="cards-info text-center">
                        <img src="../../assets/icons/profissionais-qualificados.svg" class="card-icon-home">
                        <h3 class="card-title-home">Profissionais qualificados</h3>
                        <p class="card-subtitle-home">Conectamos você a profissionais <b>psicólogos
                                especializados</b>
                        </p>
                    </div>
                </div>
                <div class="carousel-item" data-bs-interval="5000">
                    <div class="cards-info text-center">
                        <img src="../../assets/icons/atendimento-seguro.svg" class="card-icon-home">
                        <h3 class="card-title-home">Atendimento seguro</h3>
                        <p class="card-subtitle-home">Atendimento <b>familiar/casal</b> e <b>individual</b> Online,
                            seguro e a
                            <b>qualquer hora</b>
                        </p>
                    </div>
                </div>
                <div class="carousel-item" data-bs-interval="5000">
                    <div class="cards-info text-center">
                        <img src="../../assets/icons/pagamento-apos-consulta.svg" class="card-icon-home">
                        <h3 class="card-title-home">Pagamento só depois da consulta</h3>
                        <p class="card-subtitle-home">Você pode <b>pagar</b> em data <b>posterior</b> ao seu
                            <b>atendimento</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 text-center">
        <button class="btn-scheduling" (click)="scrollToServices()">Descubra abaixo sua situação</button>
    </div>
</div>
<div id="services">
    <app-services-provided></app-services-provided>
</div>
<div id="scheduling">
    <app-scheduling></app-scheduling>
</div>
<div id="questionAndAnswer">
    <app-questions-and-answers></app-questions-and-answers>
</div>
<div id="symptoms">
    <app-symptoms></app-symptoms>
</div>
<div id="serviceRules">
    <app-rules-service></app-rules-service>
</div>
<div id="whoWeAre">
    <app-who-we-are></app-who-we-are>
</div>

<app-footer></app-footer>