import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ManagePanelService } from '../services/manage-panel.service';
import Swal from 'sweetalert2';
import { SchedulingFormService } from '../services/scheduling-form.service';

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.css']
})
export class SchedulingComponent implements OnInit {
  @ViewChild('questoesPessoais', { static: false }) elPessoal: ElementRef;
  @ViewChild('questoesPessoaisMobile', { static: false }) elPessoalMobile: ElementRef;
  @ViewChild('conflitosRelacao', { static: false }) elRelacao: ElementRef;
  @ViewChild('conflitosRelacaoMobile', { static: false }) elRelacaoMobile: ElementRef;
  @ViewChild('outros', { static: false }) elOutros: ElementRef;
  @ViewChild('outrosMobile', { static: false }) elOutrosMobile: ElementRef;
  @ViewChild('closeModal') closeModal: ElementRef

  activatePersonal: boolean = false;
  activateCouple: boolean = false;
  activateOthers: boolean = false;
  selectedRadio: string = "";
  bsConfig: Partial<BsDatepickerConfig>;
  dataToShow: Observable<any>;
  formSchedule: FormGroup;
  dayScheduled: string = '';

  constructor(
    private manageService: ManagePanelService,
    private fb: FormBuilder,
    private schedulingService: SchedulingFormService,
  ) {
    this.bsConfig = Object.assign({}, { containerClass: "theme-dark-blue" });
    this.getData();
    this.formSchedule = this.fb.group({
      name: ['', Validators.required],
      email: [''],
      phone: ['', Validators.required],
      age: [''],
      reasonRadio: ['', Validators.required],
      daySelected: ['', Validators.required],
      timePicker: [''],
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    fromEvent(this.elPessoal.nativeElement, 'click').subscribe(() => this.manageActivatesVar('personal'));
    fromEvent(this.elPessoalMobile.nativeElement, 'click').subscribe(() => this.manageActivatesVar('personal'));
    fromEvent(this.elRelacao.nativeElement, 'click').subscribe(() => this.manageActivatesVar('couple'));
    fromEvent(this.elRelacaoMobile.nativeElement, 'click').subscribe(() => this.manageActivatesVar('couple'));
    fromEvent(this.elOutros.nativeElement, 'click').subscribe(() => this.manageActivatesVar('others'));
    fromEvent(this.elOutrosMobile.nativeElement, 'click').subscribe(() => this.manageActivatesVar('others'));

    this.elRelacao.nativeElement.click();
  }

  manageActivatesVar(type: string) {
    if (type === 'personal') {
      this.activatePersonal = !this.activatePersonal;
      this.activateCouple = false;
      this.activateOthers = false;
      this.formSchedule.get('reasonRadio').setValue("personalValue");
    } else if (type === 'couple') {
      this.activateCouple = !this.activateCouple;
      this.activatePersonal = false;
      this.activateOthers = false;
      this.formSchedule.get('reasonRadio').setValue("conflictValue");
    } else {
      this.activateOthers = !this.activateOthers;
      this.activatePersonal = false;
      this.activateCouple = false;
      this.formSchedule.get('reasonRadio').setValue("othersValue");
    }
  }

  activateBtn(): boolean {
    // Inverted logic because disabled of html
    return this.activatePersonal === true ? false : this.activateCouple === true ? false : this.activateOthers === true ? false : true;
  }

  submit() {
    this.formSchedule.markAllAsTouched()
    let timePicker = new Date(this.formSchedule.get('timePicker').value);
    if (this.formSchedule.valid) {
      this.formSchedule.get('timePicker').setValue(timePicker.getHours() + ':' + timePicker.getMinutes());
      this.closeModal.nativeElement.click();
      Swal.fire({
        title: 'Tudo certo',
        text: 'Registramos seus dados com sucesso. Em breve entraremos em contato.',
        icon: 'success'
      })
      this.schedulingService.insert(this.formSchedule.value);
      this.formSchedule.reset();
      this.activatePersonal = false;
      this.activateCouple = false;
      this.activateOthers = false;
    }
  }

  getData() {
    this.manageService.getSpecificData('agendamento').subscribe(data => {
      this.dataToShow = of(data[0]);
    });
  }

  changeSchedule(dayPicked) {
    this.dayScheduled = dayPicked;
    this.formSchedule.get('daySelected').setValue(dayPicked === 'today' ? 'Ainda hoje'
      : this.dayScheduled === '' ? '' : 'Outro dia');
  }

}
