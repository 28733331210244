import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(localStorage.getItem('user') !== null ? true : false);

  constructor(
    public router: Router,
    private authFirebase: AngularFireAuth,
    private toastr: ToastrService,
  ) { }

  authUser(loginData) {
    this.authFirebase.signInWithEmailAndPassword(loginData.email, loginData.password)
      .then(loginData => {
        localStorage.setItem('user', JSON.stringify(loginData));
        this.isLoggedIn$.next(true);
        this.router.navigate(['/painel-administrativo']);
      })
      .catch(error => {
        let messageError = error.code === 'auth/user-not-found' ? 'Desculpe, este e-mail não está cadastrado.' :
          error.code === 'auth/wrong-password' ? 'Senha incorreta. Verifique sua senha e tente novamente.'
            : error.code === 'auth/invalid-email' ? 'E-mail inexistente. Por favor, verifique o e-mail digitado e tente novamente.'
              : 'Erro no servidor. Por favor, Tente mais tarde.';
        this.toastr.error(messageError, 'Ah não!');
      });
  }

  async logout() {
    await this.authFirebase.signOut();
    localStorage.removeItem('user');
    this.router.navigate(['/home']);
  }
}
