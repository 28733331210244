<div class="row bg-main" style="height: auto; padding-bottom: 10em;">
    <div class="col-md-12 mt-2-4-em">
        <h3 class="main-title text-center color-title" style="margin-bottom: 3em;">Descubra abaixo o que realmente está
            sentindo</h3>
        <app-accordion tableName="sintomas" color="dark"></app-accordion>
        <!-- <div class="max-width-80-center">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <ng-container *ngFor="let cabecalho of (dataToShow | async)?.accordion">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link title-nav-tabs" id="{{cabecalho.referencia}}-tab" data-bs-toggle="tab"
                            [attr.data-bs-target]="'#' + cabecalho.referencia" type="button" role="tab"
                            aria-controls="{{cabecalho.referencia}}" aria-selected="true">{{cabecalho.titulo}}</button>
                    </li>
                </ng-container>
            </ul>
            <div class="tab-content" id="myTabContent">
                <ng-container *ngFor="let cabecalho of (dataToShow | async)?.accordion">
                    <div class="tab-pane fade" [id]="cabecalho.referencia" role="tabpanel"
                        aria-labelledby="Ansiedade-tab">
                        <p>{{ cabecalho.texto }}</p>
                    </div>
                </ng-container>
            </div>
        </div> -->
    </div>
</div>